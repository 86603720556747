$theme-1: #0075BF; // Zwemmen
$theme-2: #76B82A; // Sporten & bewegen
$theme-3: #3EA9E2; // Verhuur
$theme-4: #008237; // Ondersteuning
$theme-5: #E37823; // Main

$primary: $theme-1 !default;
$secondary: $theme-2 !default;
$dark: #06133E;
$light: #D9ECF6;
$light-gray: #F0F0F0;
$dark-gray: #575757;

$grid-breakpoints: (
        xs: 0,
        sm: 640px,
        md: 768px,
        lg: 1024px,
        xl: 1260px,
        xxl: 1510px
);

$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2,
        'theme-3': $theme-3,
        'theme-4': $theme-4,
        'theme-5': $theme-5,
        'light-gray': $light-gray,
        'dark-gray': $dark-gray,
);

$border-radius: 10px;
$border-radius-lg: $border-radius;
$border-radius-card: 25px;
$btn-border-radius: 6px;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;
$input-border-radius: $border-radius;
$custom-file-border-radius: $border-radius;
$custom-select-border-radius: $border-radius;

@import url("https://use.typekit.net/msj1icp.css");
@import "../webfonts/VAGRounded.css";

$font-family-base: "myriad-pro", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.7;

@import '../../../../src/scss/main.scss';

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, h6{
  font-family: "VAGRoundedBold", sans-serif;
  letter-spacing: 1px;
  font-weight: 400;
}

.card-body:not(:hover) {
  color: $dark-gray;
}

.img--footer-cta {
  object-fit: cover;
  opacity: 0.06;
  margin-top: -1rem;
}
.main-content {
  table {
    @extend .table;
  }
}

.page-link {
  border-color: $theme-1 !important;
}

.mobile-hamburger-color {
  @extend .my-2;
}

.hamburger {
  @extend .ml-2;
}

.mobile-hamburger-color {
  font-size: 1.1rem;
}

.navbar-brand--img {
  width: 370px;
  @extend .position-absolute;
  @include media-breakpoint-down(lg) {
    width: 280px;
  }
  @include media-breakpoint-down(sm) {
    width: 200px;
  }
  @include media-breakpoint-up(xs) {
    z-index: 2 !important;
  }
}

.subhome--header {
  height: 260px;
  @include media-breakpoint-down(md) {
    font-size: 1.3em;
    height: 150px;
  }
}

.planner-home {
  a {
    color: $theme-4,
  }
}

.navtype-white {
  .navbar-nav {
    .nav-item {
      a {
        @include media-breakpoint-up(lg) {
          color: $light !important;
        }
      }
    }
    .dropdown-menu {
      a {
        @include media-breakpoint-up(lg) {
          color: $dark !important;
        }
      }
    }
  }
}

.nav-link {
  @extend .text-wrap;
}

.ticket-overview--ticket-col {
  @extend .mb-4;
}

.w-lg-auto {
  @include media-breakpoint-up(lg) {
    marging-left: 1.5rem;
    padding: 0.5rem;
    &.show {
      background-color: $theme-1;

      a:not(.dropdown-item) {
        color: white;
      }
    }
  }
}

.dropdown-submenu {
  &:has(div.show) {
    background-color: $theme-3;
  }
  a {
    color: $dark !important;
  }
}

.sub-dropdown {
  @include media-breakpoint-up(lg) {
    position: absolute !important;
    left: -100% !important;
    width: 100%;
    top: 0;
  }
}

.navbar-nav {
  .dropdown-menu {
    @extend .mt-0;
    border-radius: 0;
    border: 0;
    background-color: $light;
    right: 0 !important;
    @include media-breakpoint-up(lg) {
      border-right: solid 1px $theme-1;
      left: auto;
    }
    a, .dropdown-toggle {
      font-weight: 600;
      color: $dark;
    }
  }
}

.footer {
  .social--icons {
    border-radius: 100%;
    margin-top: 5px;
    @extend .mr-2;
    @extend .z-2;
    @extend .position-relative;
    i {
      color: $theme-4;
      font-size: 2rem;
    }
    &:hover {
      i {
        color: $theme-1;
      }
    }
  }
}

.top-menu {
  margin-left: 380px;
  @include media-breakpoint-down(lg) {
    margin-left: 340px;
  }
  @include media-breakpoint-down(md) {
    margin-left: 290px;
  }
  @include media-breakpoint-down(sm) {
    margin-left: 220px;
  }
  @include media-breakpoint-down(xs) {
    margin-left: 0;
  }
  .social--icons {
    background-color: white;
    border-radius: 100%;
    padding: 3px 6px 3px 6px;
    margin-top: 5px;
    @extend .mr-2;
  }
  .nav-item {
    a {
      color: $dark;
    }
  }
  .login--button {
    font-family: "VAGRoundedLight", sans-serif;
    border-radius: 0;
    button, a {
      border-radius: 0;
    }
    .dropdown-menu {
      border-radius: 0;
    }
  }
}

.search {
  .card {
    background-color: white !important;
  }
  h2 {
    @extend .m-0;
  }
}

.popover, .popover-header, .popover-body {
  border-radius: 0 !important;
}

.img--agenda {
  border-radius: $border-radius 0 0 $border-radius;
  background-size: cover;
}

.img-rounded--top {
  border-radius: $border-radius $border-radius 0 0;
}

.breadcrumb {
  @extend .bg-light;
  @extend .text-dark;
  a {
    @extend .text-dark;
  }
}

.main-content {
  margin-top: 35px;
}

.home {
  @extend .pt-0;
  @include media-breakpoint-up(lg) {
    margin-top: -81px;
  }
  #Rooster {
    h3 {
      color: $dark !important;
    }
  }
}

.planner-list, #Rooster {
  h4, h3 {
    @extend .text-theme-4;
  }
  thead {
    th {
      @extend .bg-light;
      @extend .border-0;
      @extend .text-dark;
    }
    th:first-child {
      border-radius: $border-radius 0 0 $border-radius;
    }
    th:last-child {
      border-radius: 0 $border-radius $border-radius 0;
    }
  }
  .btn-block {
    @extend .d-flex;
    @extend .mx-auto;
  }
}

.img-cover {
  aspect-ratio: 2.1;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-up(lg) {
    height:450px;
    margin-top: -1.5rem;
  }
  width: 100%;
  @extend .position-relative;
  &::after {
    content: url('../svg/flavour-09.svg');
    width: 100%;
    margin-bottom: -10px;
    @extend .position-absolute;
    @extend .bottom-0;
  }
  .d-flex {
    background-color: rgba(0, 0, 0, 0.41);
  }
}

.embed-responsive-16by9 {
  aspect-ratio: 2.1;
  object-fit: cover;
}

.interest-icon {
  width: 50px;
  &.news {
    position: absolute;
    right: 20px;
    margin-top: -48px;
    background-color: white;
    border-radius: 100px;
  }
}

@mixin faded {
  &.faded-left:after {
    box-shadow: inset 70px 0 40px (-30px);
  }
}
.faded {
  position: relative;
  display: inline-block;
  color: $light;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-collapse: separate;
  }

  @include faded;
}

.card {
  @extend .bg-light-gray;
  @extend .border-0;
}

.img--side {
  height: 100% !important;
}

.main-headerless {
  @extend .mt-5;
}

.part-category-nav {
  .h3 {
    @extend .text-theme-4;
    @extend .mb-3;
  }
  a {
    @extend .text-dark;
  }
  .list-group-item {
    border-color: #a2a2a2;
  }
}

.ticket-list-item--inner {
  h6 {
    @extend h5;
    @extend .text-theme-4;
    @extend .m-0
  }
  .btn-outline-primary {
    @extend .btn-outline-theme-4;
  }
  .card-text, .fas {
    @extend .d-none;
  }
  .card-subtitle {
    @extend .my-3;
    @extend h6;
    @extend .text-theme-4;
  }
}

.col--bordered {
  border-radius: 0 200px 200px 0;
  background-color: $light;
  padding: 0 0 10px;
  @extend .d-flex;
  .image--cta-left {
    border-radius: 0 200px 200px 0;
    max-width: 700px;
  }
}

.banner--flavour-top {
  margin-bottom: -4px;
  aspect-ratio: 20.1;
  min-width: 100%;
}
.banner--flavour-side {
  max-width: 90px !important;
  margin-top: -60px;
}

.img--accordion {
  width: 50px;
  height: 50px;
}

.banner--flavour-back {
  max-height: 170px;
  aspect-ratio: 4.3;
  height: 100%;
  width: 100%;
  top: 30px !important;
  @include media-breakpoint-down(md) {
    top: 0 !important;
    height: 140px;
  }
}

.banner--flavour-footer {
  margin-bottom: -108px;
  img {
    transform: translateY(calc(-33% - 2px));
    width: 200px;
  }
}

.flavor-detail {
  @extend .right-0;
  width: 110px;
  top: 90px;
  opacity: 0.7;
}

.img--dot {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: -20px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: $theme-2;
}

.banner--flavour {
  height: 200px;
  margin-bottom: -1px;
  @include media-breakpoint-down(lg) {
    height: 170px;
  }
  @include media-breakpoint-down(md) {
    height: 120px;
  }
  @include media-breakpoint-down(sm) {
    height: 70px;
  }
}

.card--extra-rounded, .ticket-list-item--inner {
  border-radius: $border-radius $border-radius 60px $border-radius !important;
}

.rounded-circle {
  border-radius: 50%;
}

.img--cta2 {
  max-width: 200px;
}

.footer {
  h4 {
    text-transform: uppercase;
  }
  .footer--muis {
    width: 300px;
    bottom: -100px !important;
    top: unset;
    margin-right: 10px;
    @include media-breakpoint-down(md) {
      top: 0;
      bottom: unset;
      margin-top: 100px;
      width: 300px;
    }
    @include media-breakpoint-down(sm) {
      width: 250px;
    }
    @include media-breakpoint-down(xs) {
      width: 200px;
    }
  }
}

.display-4 {
  @include media-breakpoint-down(md) {
    font-size: 3em;
    margin-left: 0.2em !important;
    margin-bottom: 0.5em !important;
  }
}
.navbar-expand-lg {
  .container-fluid {
    @include media-breakpoint-down(md) {
      border-bottom: solid 1px $light;
    }
  }
}

.card--home-interest {
  .card {
    border-radius: $border-radius-card;
    transition: background-color 0.5s;
  }
  &.offset {
    @include media-breakpoint-up(lg) {
      margin-left: -65px;
    }
  }
  &:hover {
    color: white !important;
    h5, a, span {
      color: white !important;
      text-decoration: none;
    }
    img {
      filter: invert(100%) sepia(100%) saturate(21%) hue-rotate(321deg) brightness(205%) contrast(800%)
    }
    .interest-1 {
      background-color: $theme-1 !important;
    }
    .interest-2 {
      background-color: $theme-2 !important;
    }
    .interest-3 {
      background-color: $theme-3 !important;
    }
    .interest-4 {
      background-color: $theme-4 !important;
    }
  }
}

.text-bottom {
  max-width: 600px;
}

.hard-light {
  mix-blend-mode: hard-light;
}

.bg-theme-1-opacity {
  background-color: rgba(0, 117, 191, 0.65);
}
.bg-theme-2-opacity {
  background-color: rgba(83, 140, 15, 0.65);
}
.bg-theme-3-opacity {
  background-color: rgba(62, 169, 226, 0.65);
}
.bg-theme-4-opacity {
  background-color: rgba(0, 107, 45, 0.65);
}

.header--background {
  z-index: -1;
}

.h-fit {
  height: fit-content;
}

.card-hover--theme-1 {
  &:hover {
    @extend .bg-theme-1;
    h6 {
      @extend .text-white;
    }
  }
}

.card-hover--theme-2 {
  &:hover {
    @extend .bg-theme-2;
    h6 {
      @extend .text-white;
    }
  }
}

.card-hover--theme-3 {
  &:hover {
    @extend .bg-theme-3;
    h6 {
      @extend .text-white;
    }
  }
}

.card-hover--theme-4 {
  &:hover {
    @extend .bg-theme-4;
    h6 {
      @extend .text-white;
    }
  }
}

#main-content {
  min-height: 60vh;
  padding-bottom: 2.5rem;
  @extend .pt-4;
}

.planner-table {
  tbody {
    tr:first-child {
      td {
        border-top: 0;
      }
    }
  }
}

.nav-item {
  a {
    font-family: "VAGRoundedLight", sans-serif;
  }
}

.homeherophoto {
  @extend .rounded;
}

.img-responsive {
  object-fit: cover;
}

.hero-video--thumb {
  @extend .w-100;
}

.card-stretch-hover {
  .card-img-top {
    width: 100%;
    transition: transform .3s;
    @extend .h-auto;
  }
  &:hover {
    .card-img-top {
      transform: scale(1.1);
    }
  }
}

.nav-cart .badge {
  right: unset;
  left: -11px !important;
  padding-top: 5px;
  @include media-breakpoint-down(md) {
    top: -5px !important;
  }
}

@import '@/src/scss/components/mijn-sidebar.scss'; // Mijn omgeving css (sidebar theme)
