/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: VAG Rounded Complete Family Pack by Linotype
 * URL: https://www.myfonts.com/products/vag-rounded-complete-family-pack-package-379933
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */



@font-face {
  font-family: "VAGRoundedBold";
  src: url('VAGRoundedBold/font.woff2') format('woff2'), url('VAGRoundedBold/font.woff') format('woff');
}
@font-face {
  font-family: "VAGRoundedLight";
  src: url('VAGRoundedLight/font.woff2') format('woff2'), url('VAGRoundedLight/font.woff') format('woff');
}
@font-face {
  font-family: "VAGRoundedThin";
  src: url('VAGRoundedThin/font.woff2') format('woff2'), url('VAGRoundedThin/font.woff') format('woff');
}
@font-face {
  font-family: "VAGRoundedBlack";
  src: url('VAGRoundedBlack/font.woff2') format('woff2'), url('VAGRoundedBlack/font.woff') format('woff');
}

